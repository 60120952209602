$(document).ready(function() {
  $('.js-btn-menu').on('click', function() {
    $('body').toggleClass('js-menu--in');
  });
  $('#overlay').on('click', function() {
    $('body').removeClass('js-menu--in');
  });
  if($('.nav-menu').children().length == 0) {
    $('.btn-menu').hide();
  }
});